@import "../../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/variables";

$color-primary: white;

$search-border-radius: 5px;
$search-bg-color: rgba(0, 0, 0, 0.1);
$search-icon-color: #3AAFA9;
$search-input-bg-color: transparent;

$button-attachment-color: #3AAFA9;
$button-send-color: #3AAFA9;

$message-input-bg-color: transparent;
$message-input-content-editor-wrapper-bg-color: white;
$message-input-content-editor-bg-color: white;

$message-content-color: white;
$message-content-incoming-color: white;
$message-content-bg-color: #1e1f1e;
$message-content-incoming-bg-color: #1e1f1e;
$message-content-outgoing-color: white;
$message-content-outgoing-bg-color: #3AAFA9;
$message-content-border-radius: 0.5em 0.5em 0.5em 0;
$message-incoming-content-border-radius: 0.5em 0.5em 0.5em 0;
$message-incoming-content-single-border-radius: 0.5em 0.5em 0.5em 0;
$message-outgoing-content-border-radius: 0.5em 0.5em 0 0.5em;

$message-sent-time-color: rgba(0, 0, 0, 0.8);
$message-sender-name-color: rgba(0, 0, 0, 0.8);

$message-separator-color: rgba(0, 0, 0, 0.8);
$message-separator-bg-color: transparent;

$message-list-loading-more-bg-color: transparent;

//Flags
$show-message-incoming-sent-time: true;
$show-message-incoming-single-sent-time: true;
$show-message-incoming-sender-name: true;
$show-message-outgoing-sent-time: true;
$show-message-outgoing-single-sent-time: true;
$show-message-outgoing-sender-name: true;
