@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
  margin: 0;
  font-family: 'Open Sans',serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
